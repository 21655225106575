<template>
  <account-linkage-table-gql :columns="columns" />
</template>
<script>
import AccountLinkageTableGql from '@/components/entity/accountLinkage/AccountLinkageTableGql.vue'

export default {
  components: { AccountLinkageTableGql },
  data() {
    return {
      columns: [
        {
          name: 'customQuickSearch', label: 'Quick search', filterable: 'like', visible: false,
        },
        {
          name: 'searchUser',
          label: 'User',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email', visible: true,
          },
          visible: true,
        },
        {
          name: 'account', label: 'Account',
        },
        {
          name: 'targetUser',
          label: 'Target user',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email', visible: true,
          },
          visible: true,
        },
        {
          name: 'linked_account', label: 'Linked account',
        },
        {
          name: 'createdAt', label: 'Created at',
        },
        {
          name: 'country',
          label: 'Country',
          filterable: {
            type: 'entity', queryType: 'countries', value: 'id', label: 'name', search: 'name', visible: true,
          },
          visible: false,
        },
      ],
    }
  },
}
</script>
